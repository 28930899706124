<script setup>
const colors = {
	error: 'error',
	success: 'success',
}
const snackbar = inject('snackbar')
snackbar.value = snackbarSet({ isVisible: false })
const isVisible = ref(snackbar.value.isVisible)
const type = ref(snackbar.value.type)
const text = ref(snackbar.value.text)
const color = ref(colors[type.value])

watch(
	() => snackbar,
	() => {
		isVisible.value = snackbar.value.isVisible
		type.value = snackbar.value.type
		color.value = colors[snackbar.value.type]
		text.value = snackbar.value.text
	},
	{ deep: true }
)
</script>

<template>
	<VSnackbar v-model="isVisible" :color="color" location="top">
		<slot>
			{{ $t(text) }}
		</slot>

		<template v-slot:actions>
			<VBtn color="pink" variant="text" @click="isVisible = false" icon="tabler-x" />
		</template>
	</VSnackbar>
</template>
